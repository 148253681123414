@import "../../../styles/global-scss.scss", "../../../styles/mixins.scss";

:local(.firstView) {
  display: flex;
  flex-direction: column;
  justify-content: center;

  width: 100%;
  height: 100%;
  
  :global {
    .image-wrapper {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100svh;

      &:after {
        content: '';
        display: block;
        @include position(absolute, 0,0,0,0);
        background-color: var(--color-D0);
        opacity: .4;
      }

      > img {
        position: absolute;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .content {
      max-width: var(--column6);
      margin: auto;
      padding: var(--side-margin);

      @media screen and (max-width: $br2-end) {
        max-width: 400px;
      }

      >.mobile-logo {
        --logo-size: 90px;
        width: var(--logo-size);
        height: var(--logo-size);
        margin: 0 auto var(--spacing-1);


        @media screen and (min-width: $br1-start) {
          display: none;
        }
       
        > img {
          width: 100%;
          height: 100%;
        }
      }
      
      >.typography-h2, >.typography-h3 {
        text-align: center;
        margin-bottom: var(--spacing-2);
      }

      > button {
        margin: 0 auto;
      }
    }

  }
}