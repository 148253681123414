@import "../../../styles/global-scss.scss", "../../../styles/mixins.scss";
:local(.services) {
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: var(--column6);

  :global {
    .main-title {
      margin-bottom: var(--spacing-1);
    }

    ul {
      display: flex;
      flex-wrap: wrap;
      grid-gap: var(--spacing-3);

      > li {
        border-radius: var(--radius-2);
        padding: 0 var(--spacing-2);

        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        
        background-color: var(--color-C0);
        color: var(--color-A0);

        &:first-letter {
          text-transform: uppercase;
        }
      }
    }
  }
}
