@import "../../../styles/global-scss.scss";

:local(.members) {
  :global {
    .hiden-insta-parh-gradient {
      position: absolute;
      opacity: 0;
      pointer-events: none;
      user-select: none;
    }
    
    ul {
      display: grid;
      grid-gap: var(--spacing-0) 0;

      @media screen and (min-width: $br1-1-start) {
        grid-template-columns: repeat(4, 1fr);
        >li:nth-child(5) {
          grid-column: 2 / span 1;
        }
      }
      @media screen and (max-width: $br1-1-end) and (min-width: $br1-2-start) {
        grid-template-columns: repeat(3, 1fr);
      }
      @media screen and (max-width: $br1-2-end) and (min-width: $br1-start) {
        grid-template-columns: repeat(2, 1fr);
      }
      @media screen and (max-width: $br2-end) {
        grid-template-columns: repeat(1, 1fr);
      }

      >li {
        @media screen and (max-width: $br2-end) {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
        }

        *:not(.socials, a, img) {
          user-select: none;
          pointer-events: none;
        }

        > :not(.avatar) {
          padding: 0 var(--spacing-2);
        }

        > .avatar {
          position: relative;
          width: 100%;
          max-width: 240px;

          &::before {
            content: '';
            display: block;
            padding-top: 125%;
          }

          > img {
            @include position(absolute,0,0,0,0);
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }

        > .name {
          margin: var(--spacing-3) 0 0;
          // margin: var(--spacing-1) 0 var(--spacing-3);
        }

        > .aditional {
          max-width: 260px;
          
          @media screen and (max-width: $br2-end) {
            text-align: center;
          }
        }

        > .socials {
          display: flex;
          grid-gap: var(--spacing-3) 0;
          // margin-top: var(--spacing-3);

          > a {
            display: block;
            height: var(--size);
            width: var(--size);

            @media screen and (min-width: $br1-1-start) {
              --size: 26px;
            }
            @media screen and (max-width: $br1-1-end) and (min-width: $br1-2-start) {
              --size: 25px;
            }
            @media screen and (max-width: $br1-2-end) and (min-width: $br1-start) {
              --size: 25px;
              margin-top: var(--subline);
            }
            @media screen and (max-width: $br2-end) {
              --size: 25px;
              margin-top: var(--subline);
            }

            @media screen and (min-width: $br1-2-start) {
              &:not(:last-child) {
                margin-right: 3px;
              }
            }
            @media screen and (max-width: $br1-2-end) {
              &:not(:last-child) {
                margin-right: var(--subline);
              }
            }

            > * {
              height: 100%;
              width: 100%;
              object-fit: cover;

              &.viber-bg {
                fill: #7360f2
              }
              &.wa-bg {
                fill: #128C7E
              }
              &.insta-bg {
                fill: url(#instagramRadialGradient)
              }
              &.fb-bg {
                fill: #3b5998
              }
            }
          }
        }
      }
    }
  }
}