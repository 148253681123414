@import "../../../styles/mixins.scss",
        "../../../styles/global-scss.scss";

:local(.button) {
  --button-height: 50px;
  background-color: transparent;
  border-color: var(--color-A0);
  $properties: background-color, border-color;
  @include transition($properties);
  //
  height: var(--button-height);
  padding: 0 calc(var(--button-height) / 2);
  width: fit-content;
  border: 1px solid var(--color-A0);
  //
  border-radius: 25px;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  cursor: pointer;
  position: relative;

  @media (pointer: fine) {
    &:hover {
      background-color: var(--color-C0);
      border-color: var(--color-C0);
    }
  }

  :global {
    p {
      pointer-events: none;
      white-space: nowrap;
    }
  } 
}

