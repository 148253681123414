/*
	shuster i partnery v1.19
	(c) Flareum, 2023
*/

@mixin typography-h1 {
  @media screen and (max-width: $br2-end) {
    &:not(.style-1) {
      font-family: var(--font-f1);
      font-size: var(--font-s4);
      font-weight: 400;
      line-height: 81px;
      letter-spacing: 0px;
      text-align: left;
      text-transform: none;
      text-decoration: none;
      font-smooth: antialiased;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: auto;
      /* color slot css varible(-s) to use in font states :hover, :active, .<custome-state-name> etc */
      --slot-color: var(--color-C0);
      color: var(--slot-color);

      &::selection {
        background-color: var(--color-C0);
        color: var(--color-C0);
      }
    }

    &.style-1 {
      font-family: var(--font-f1);
      font-size: var(--font-s4);
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0px;
      text-align: left;
      text-transform: uppercase;
      text-decoration: none;
      font-smooth: antialiased;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: auto;
      /* color slot css varible(-s) to use in font states :hover, :active, .<custome-state-name> etc */
      --slot-color: var(--color-C0);
      --slot-color-white: var(--color-A0);

      &.white {
        color: var(--slot-color-white);

        &::selection {
          background-color: var(--color-A0);
          color: var(--color-A0);
        }
      }

      &:not(.white) {
        color: var(--slot-color);

        &::selection {
          background-color: var(--color-C0);
          color: var(--color-C0);
        }
      }
    }
  }

  @media screen and (max-width: $br1-end) and (min-width: $br1-start) {
    &:not(.style-1) {
      font-family: var(--font-f1);
      font-size: var(--font-s4);
      font-weight: 400;
      line-height: 83px;
      letter-spacing: 0px;
      text-align: left;
      text-transform: none;
      text-decoration: none;
      font-smooth: antialiased;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: auto;
      /* color slot css varible(-s) to use in font states :hover, :active, .<custome-state-name> etc */
      --slot-color: var(--color-C0);
      color: var(--slot-color);

      &::selection {
        background-color: var(--color-C0);
        color: var(--color-C0);
      }
    }

    &.style-1 {
      font-family: var(--font-f1);
      font-size: var(--font-s4);
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0px;
      text-align: left;
      text-transform: uppercase;
      text-decoration: none;
      font-smooth: antialiased;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: auto;
      /* color slot css varible(-s) to use in font states :hover, :active, .<custome-state-name> etc */
      --slot-color: var(--color-C0);
      --slot-color-white: var(--color-A0);

      &.white {
        color: var(--slot-color-white);

        &::selection {
          background-color: var(--color-A0);
          color: var(--color-A0);
        }
      }

      &:not(.white) {
        color: var(--slot-color);

        &::selection {
          background-color: var(--color-C0);
          color: var(--color-C0);
        }
      }
    }
  }

  /* primary breakpoint - $br0-end(1025)px */
  @media screen and (min-width: $br0-start) {
    &:not(.style-1) {
      font-family: var(--font-f1);
      font-size: var(--font-s4);
      font-weight: 400;
      line-height: 83px;
      letter-spacing: 0px;
      text-align: left;
      text-transform: none;
      text-decoration: none;
      font-smooth: antialiased;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: auto;
      /* color slot css varible(-s) to use in font states :hover, :active, .<custome-state-name> etc */
      --slot-color: var(--color-C0);
      color: var(--slot-color);

      &::selection {
        background-color: var(--color-C0);
        color: var(--color-C0);
      }
    }

    &.style-1 {
      font-family: var(--font-f1);
      font-size: var(--font-s4);
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0px;
      text-align: center;
      text-transform: uppercase;
      text-decoration: none;
      font-smooth: antialiased;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: auto;
      /* color slot css varible(-s) to use in font states :hover, :active, .<custome-state-name> etc */
      --slot-color: var(--color-C0);
      --slot-color-white: var(--color-A0);

      &.white {
        color: var(--slot-color-white);

        &::selection {
          background-color: var(--color-A0);
          color: var(--color-A0);
        }
      }

      &:not(.white) {
        color: var(--slot-color);

        &::selection {
          background-color: var(--color-C0);
          color: var(--color-C0);
        }
      }
    }
  }
}

@mixin typography-h2 {
  @media screen and (max-width: $br2-end) {
    font-family: var(--font-f1);
    font-size: var(--font-s3);
    font-weight: 400;
    line-height: 47px;
    letter-spacing: 0px;
    text-align: left;
    text-transform: none;
    text-decoration: none;
    font-smooth: antialiased;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: auto;
    /* color slot css varible(-s) to use in font states :hover, :active, .<custome-state-name> etc */
    --slot-color: var(--color-C0);
    --slot-color-white: var(--color-A0);

    &.white {
      color: var(--slot-color-white);

      &::selection {
        background-color: var(--color-A0);
        color: var(--color-A0);
      }
    }

    &:not(.white) {
      color: var(--slot-color);

      &::selection {
        background-color: var(--color-C0);
        color: var(--color-C0);
      }
    }
  }

  @media screen and (max-width: $br1-end) and (min-width: $br1-start) {
    font-family: var(--font-f1);
    font-size: var(--font-s3);
    font-weight: 400;
    line-height: 48px;
    letter-spacing: 0px;
    text-align: left;
    text-transform: none;
    text-decoration: none;
    font-smooth: antialiased;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: auto;
    /* color slot css varible(-s) to use in font states :hover, :active, .<custome-state-name> etc */
    --slot-color: var(--color-C0);
    --slot-color-white: var(--color-A0);

    &.white {
      color: var(--slot-color-white);

      &::selection {
        background-color: var(--color-A0);
        color: var(--color-A0);
      }
    }

    &:not(.white) {
      color: var(--slot-color);

      &::selection {
        background-color: var(--color-C0);
        color: var(--color-C0);
      }
    }
  }

  /* primary breakpoint - $br0-end(1025)px */
  @media screen and (min-width: $br0-start) {
    font-family: var(--font-f1);
    font-size: var(--font-s3);
    font-weight: 500;
    line-height: 48px;
    letter-spacing: 0px;
    text-align: left;
    text-transform: uppercase;
    text-decoration: none;
    font-smooth: antialiased;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: auto;
    /* color slot css varible(-s) to use in font states :hover, :active, .<custome-state-name> etc */
    --slot-color: var(--color-C0);
    --slot-color-white: var(--color-A0);

    &.white {
      color: var(--slot-color-white);

      &::selection {
        background-color: var(--color-A0);
        color: var(--color-A0);
      }
    }

    &:not(.white) {
      color: var(--slot-color);

      &::selection {
        background-color: var(--color-C0);
        color: var(--color-C0);
      }
    }
  }
}

@mixin typography-h3 {
  @media screen and (max-width: $br2-end) {
    font-family: var(--font-f1);
    font-size: var(--font-s2);
    font-weight: 400;
    line-height: 33px;
    letter-spacing: 0px;
    text-align: left;
    text-transform: none;
    text-decoration: none;
    font-smooth: antialiased;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: auto;
    /* color slot css varible(-s) to use in font states :hover, :active, .<custome-state-name> etc */
    --slot-color: var(--color-C0);
    --slot-color-white: var(--color-A0);

    &.white {
      color: var(--slot-color-white);

      &::selection {
        background-color: var(--color-A0);
        color: var(--color-A0);
      }
    }

    &:not(.white) {
      color: var(--slot-color);

      &::selection {
        background-color: var(--color-C0);
        color: var(--color-C0);
      }
    }
  }

  @media screen and (max-width: $br1-end) and (min-width: $br1-start) {
    font-family: var(--font-f1);
    font-size: var(--font-s2);
    font-weight: 400;
    line-height: 36px;
    letter-spacing: 0px;
    text-align: left;
    text-transform: none;
    text-decoration: none;
    font-smooth: antialiased;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: auto;
    /* color slot css varible(-s) to use in font states :hover, :active, .<custome-state-name> etc */
    --slot-color: var(--color-C0);
    --slot-color-white: var(--color-A0);

    &.white {
      color: var(--slot-color-white);

      &::selection {
        background-color: var(--color-A0);
        color: var(--color-A0);
      }
    }

    &:not(.white) {
      color: var(--slot-color);

      &::selection {
        background-color: var(--color-C0);
        color: var(--color-C0);
      }
    }
  }

  /* primary breakpoint - $br0-end(1025)px */
  @media screen and (min-width: $br0-start) {
    font-family: var(--font-f1);
    font-size: var(--font-s2);
    font-weight: 400;
    line-height: 36px;
    letter-spacing: 0px;
    text-align: left;
    text-transform: none;
    text-decoration: none;
    font-smooth: antialiased;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: auto;
    /* color slot css varible(-s) to use in font states :hover, :active, .<custome-state-name> etc */
    --slot-color: var(--color-C0);
      --slot-color-white: var(--color-A0);

      &.white {
        color: var(--slot-color-white);

        &::selection {
          background-color: var(--color-A0);
          color: var(--color-A0);
        }
      }

      &:not(.white) {
        color: var(--slot-color);

        &::selection {
          background-color: var(--color-C0);
          color: var(--color-C0);
        }
      }
  }
}

@mixin typography-t1 {
  @media screen and (max-width: $br2-end) {
    &:not(.style-1) {
      font-family: var(--font-f1);
      font-size: var(--font-s0);
      font-weight: 400;
      line-height: 21px;
      letter-spacing: 0px;
      text-align: left;
      text-transform: none;
      text-decoration: none;
      font-smooth: antialiased;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: auto;
      /* color slot css varible(-s) to use in font states :hover, :active, .<custome-state-name> etc */
      --slot-color: var(--color-C0);
      --slot-color-white: var(--color-A0);

      &.white {
        color: var(--slot-color-white);

        &::selection {
          background-color: var(--color-A0);
          color: var(--color-A0);
        }
      }

      &:not(.white) {
        color: var(--slot-color);

        &::selection {
          background-color: var(--color-C0);
          color: var(--color-C0);
        }
      }
    }

    &.style-1 {
      font-family: var(--font-f1);
      font-size: var(--font-s0);
      font-weight: 500;
      line-height: 24px;
      letter-spacing: 0px;
      text-align: left;
      text-transform: uppercase;
      text-decoration: none;
      font-smooth: antialiased;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: auto;
      /* color slot css varible(-s) to use in font states :hover, :active, .<custome-state-name> etc */
      --slot-color: var(--color-C0);
      --slot-color-white: var(--color-A0);
    
      &.white {
        color: var(--slot-color-white);
    
        &::selection {
          background-color: var(--color-A0);
          color: var(--color-A0);
        }
      }
    
      &:not(.white) {
        color: var(--slot-color);
    
        &::selection {
          background-color: var(--color-C0);
          color: var(--color-C0);
        }
      }
    }
  }

  @media screen and (max-width: $br1-end) and (min-width: $br1-start) {
    &:not(.style-1) {
      font-family: var(--font-f1);
      font-size: var(--font-s0);
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0px;
      text-align: left;
      text-transform: none;
      text-decoration: none;
      font-smooth: antialiased;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: auto;
      /* color slot css varible(-s) to use in font states :hover, :active, .<custome-state-name> etc */
      --slot-color: var(--color-C0);
      --slot-color-white: var(--color-A0);

      &.white {
        color: var(--slot-color-white);

        &::selection {
          background-color: var(--color-A0);
          color: var(--color-A0);
        }
      }

      &:not(.white) {
        color: var(--slot-color);

        &::selection {
          background-color: var(--color-C0);
          color: var(--color-C0);
        }
      }
    }

    &.style-1 {
      font-family: var(--font-f1);
      font-size: var(--font-s0);
      font-weight: 500;
      line-height: 24px;
      letter-spacing: 0px;
      text-align: left;
      text-transform: uppercase;
      text-decoration: none;
      font-smooth: antialiased;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: auto;
      /* color slot css varible(-s) to use in font states :hover, :active, .<custome-state-name> etc */
      --slot-color: var(--color-C0);
      --slot-color-white: var(--color-A0);
    
      &.white {
        color: var(--slot-color-white);
    
        &::selection {
          background-color: var(--color-A0);
          color: var(--color-A0);
        }
      }
    
      &:not(.white) {
        color: var(--slot-color);
    
        &::selection {
          background-color: var(--color-C0);
          color: var(--color-C0);
        }
      }
    }
  }

  /* primary breakpoint - $br0-end(1025)px */
  @media screen and (min-width: $br0-start) {
    &:not(.style-1) {
      font-family: var(--font-f1);
      font-size: var(--font-s0);
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0px;
      text-align: left;
      text-transform: none;
      text-decoration: none;
      font-smooth: antialiased;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: auto;
      /* color slot css varible(-s) to use in font states :hover, :active, .<custome-state-name> etc */
      --slot-color: var(--color-C0);
      --slot-color-white: var(--color-A0);

      &.white {
        color: var(--slot-color-white);

        &::selection {
          background-color: var(--color-A0);
          color: var(--color-A0);
        }
      }

      &:not(.white) {
        color: var(--slot-color);

        &::selection {
          background-color: var(--color-C0);
          color: var(--color-C0);
        }
      }
    }

    &.style-1 {
      font-family: var(--font-f1);
      font-size: var(--font-s0);
      font-weight: 500;
      line-height: 24px;
      letter-spacing: 0px;
      text-align: left;
      text-transform: uppercase;
      text-decoration: none;
      font-smooth: antialiased;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: auto;
      /* color slot css varible(-s) to use in font states :hover, :active, .<custome-state-name> etc */
      --slot-color: var(--color-C0);
      --slot-color-white: var(--color-A0);
    
      &.white {
        color: var(--slot-color-white);
    
        &::selection {
          background-color: var(--color-A0);
          color: var(--color-A0);
        }
      }
    
      &:not(.white) {
        color: var(--slot-color);
    
        &::selection {
          background-color: var(--color-C0);
          color: var(--color-C0);
        }
      }
    }
  }
}

@mixin typography-t2 {
  @media screen and (max-width: $br2-end) {
    &:not(.style-1) {
      font-family: var(--font-f1);
      font-size: var(--font-s-1);
      font-weight: 400;
      line-height: 16px;
      letter-spacing: 0px;
      text-align: left;
      text-transform: none;
      text-decoration: none;
      font-smooth: antialiased;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: auto;
      /* color slot css varible(-s) to use in font states :hover, :active, .<custome-state-name> etc */
      --slot-color: var(--color-C0);
      --slot-color-white: var(--color-A0);

      &.white {
        color: var(--slot-color-white);

        &::selection {
          background-color: var(--color-A0);
          color: var(--color-A0);
        }
      }

      &:not(.white) {
        color: var(--slot-color);

        &::selection {
          background-color: var(--color-C0);
          color: var(--color-C0);
        }
      }
    }

    &.style-1 {
      font-family: var(--font-f1);
      font-size: var(--font-s-1);
      font-weight: 500;
      line-height: 16px;
      letter-spacing: 0px;
      text-align: left;
      text-transform: uppercase;
      text-decoration: none;
      font-smooth: antialiased;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: auto;
      /* color slot css varible(-s) to use in font states :hover, :active, .<custome-state-name> etc */
      --slot-color: var(--color-C0);
      --slot-color-white: var(--color-A0);
    
      &.white {
        color: var(--slot-color-white);
    
        &::selection {
          background-color: var(--color-A0);
          color: var(--color-A0);
        }
      }
    
      &:not(.white) {
        color: var(--slot-color);
    
        &::selection {
          background-color: var(--color-C0);
          color: var(--color-C0);
        }
      }
    }
  }

  @media screen and (max-width: $br1-end) and (min-width: $br1-start) {
    &:not(.style-1) {
      font-family: var(--font-f1);
      font-size: var(--font-s-1);
      font-weight: 400;
      line-height: 16px;
      letter-spacing: 0px;
      text-align: left;
      text-transform: none;
      text-decoration: none;
      font-smooth: antialiased;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: auto;
      /* color slot css varible(-s) to use in font states :hover, :active, .<custome-state-name> etc */
      --slot-color: var(--color-C0);
      --slot-color-white: var(--color-A0);

      &.white {
        color: var(--slot-color-white);

        &::selection {
          background-color: var(--color-A0);
          color: var(--color-A0);
        }
      }

      &:not(.white) {
        color: var(--slot-color);

        &::selection {
          background-color: var(--color-C0);
          color: var(--color-C0);
        }
      }
    }

    &.style-1 {
      font-family: var(--font-f1);
      font-size: var(--font-s-1);
      font-weight: 500;
      line-height: 16px;
      letter-spacing: 0px;
      text-align: left;
      text-transform: uppercase;
      text-decoration: none;
      font-smooth: antialiased;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: auto;
      /* color slot css varible(-s) to use in font states :hover, :active, .<custome-state-name> etc */
      --slot-color: var(--color-C0);
      --slot-color-white: var(--color-A0);
    
      &.white {
        color: var(--slot-color-white);
    
        &::selection {
          background-color: var(--color-A0);
          color: var(--color-A0);
        }
      }
    
      &:not(.white) {
        color: var(--slot-color);
    
        &::selection {
          background-color: var(--color-C0);
          color: var(--color-C0);
        }
      }
    }
  }

  /* primary breakpoint - $br0-end(1025)px */
  @media screen and (min-width: $br0-start) {
    &:not(.style-1) {
      font-family: var(--font-f1);
      font-size: var(--font-s-1);
      font-weight: 400;
      line-height: 16px;
      letter-spacing: 0px;
      text-align: left;
      text-transform: none;
      text-decoration: none;
      font-smooth: antialiased;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: auto;
      /* color slot css varible(-s) to use in font states :hover, :active, .<custome-state-name> etc */
      --slot-color: var(--color-C0);
      --slot-color-white: var(--color-A0);

      &.white {
        color: var(--slot-color-white);

        &::selection {
          background-color: var(--color-A0);
          color: var(--color-A0);
        }
      }

      &:not(.white) {
        color: var(--slot-color);

        &::selection {
          background-color: var(--color-C0);
          color: var(--color-C0);
        }
      }
    }

    &.style-1 {
      font-family: var(--font-f1);
      font-size: var(--font-s-1);
      font-weight: 500;
      line-height: 16px;
      letter-spacing: 0px;
      text-align: left;
      text-transform: uppercase;
      text-decoration: none;
      font-smooth: antialiased;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: auto;
      /* color slot css varible(-s) to use in font states :hover, :active, .<custome-state-name> etc */
      --slot-color: var(--color-C0);
      --slot-color-white: var(--color-A0);
    
      &.white {
        color: var(--slot-color-white);
    
        &::selection {
          background-color: var(--color-A0);
          color: var(--color-A0);
        }
      }
    
      &:not(.white) {
        color: var(--slot-color);
    
        &::selection {
          background-color: var(--color-C0);
          color: var(--color-C0);
        }
      }
    }
  }
}