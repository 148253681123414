/*
	shuster i partnery v1.15
	(c) Flareum, 2023
*/
/* break points */
/* custome */
$br1-1-start: 1101px;
$br1-1-end: 1100px;
$br1-2-start: 841px;
$br1-2-end: 840px;
/* To save your code dependences we used real break point keys. If you want to use the same names like in the design please rename it manualy in whole app ;) */
$br2-start: 0px;
$br2-end: 678px;
$br1-start: 679px;
$br1-end: 1024px;
$br0-start: 1025px;
$br0-end: 9999px;
$retina: '(-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi)';
/* colors */
$color-A0: rgba(231, 231, 224, 1);
$color-P0: rgba(122, 99, 240, 1);
$color-B0: rgba(116, 224, 224, 1);
$color-C0: rgba(30, 30, 16, 1);
$color-D0: rgba(12, 12, 0, 1);
/* grid */
$baseline: 24px;
$subline: 6px;
$gutter: 24px;
$column1: 78px;
$column2: 180px;
$column3: 282px;
$column4: 384px;
$column5: 486px;
$column6: 588px;
$column7: 690px;
$column8: 792px;
$column9: 894px;
$column10: 996px;
$column11: 1098px;
$column12: 1200px;
$content-width: $column12;
/* fonts */
$font-s-1: 13px;
$font-s-2: 9px;
$font-s-3: 8px;
$font-s0: 16px;
$font-s1: 19px;
$font-s2: 24px;
$font-s3: 32px;
$font-s4: 55px;
$font-s5: 97px;
$font-s6: 114px;
$font-s7: 120px;
$font-f1: 'Roboto';

/* transition / animation */
$ease-out-apple1: cubic-bezier(0.165, 0.84, 0.44, 1);
$ease-out-apple2: cubic-bezier(0.42, 0, 0.58, 1);


$base-time: 0.3s;
$time-1: $base-time * 2;
$time-2: $base-time * 3;
$time-3: $base-time * 4;

$base-delay: 0.2s;
$delay1: $base-delay * 2;
$delay2: $base-delay * 4;
$delay3: $base-delay * 6;
$delay4: $base-delay * 6.5;
$delay5: $base-delay * 8;
$delay6: $base-delay * 10.5;
