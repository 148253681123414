/*
	shuster i partnery v1.10
	(c) Flareum, 2023
*/
@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap');
@import './global-scss.scss',
	'./css-vars.scss';
b,em,i,small,strong,sub,sup,ins,del,mark,code,kbd,samp,var,pre,abbr,bdo,q,cite,blockquote,dfn,br,hr,p,h1,h2,h3,h4,h5,h6,p,a,li,ul,div,wbr{
	-webkit-font-smoothing: antialiased;
	color: var(--color-D0);
}
.typography-h1{@include typography-h1();}
.typography-h2{@include typography-h2();}
.typography-h3{@include typography-h3();}
.typography-t1{@include typography-t1();}
.typography-t2{@include typography-t2();}

* {
  margin: 0;
  padding: 0;
}

img,
video,
svg {
  user-select: none;
}

html {
  background-color: var(--main-background);
}
body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  --header-height: calc(var(--baseline) * 2 - var(--subline) * 2);
}

body {
  width: 100vw;
  height: 100%;
  overflow-x: hidden;
}

/* Reset `button` and button-style `input` default styles */
input[type="submit"],
input[type="reset"],
input[type=""],
button {
  background: none;
  border: 0;
  /* cursor: default; */
  font: inherit;
  line-height: normal;
  overflow: visible;
  padding: 0;
  -webkit-appearance: button; /* for input */
  -webkit-user-select: none; /* for button */
  -moz-user-select: none;
  -ms-user-select: none;
}
input::-moz-focus-inner,
button::-moz-focus-inner {
  border: 0;
  padding: 0;
}


.app{
  &.disable{
    overflow: hidden;
  }
}


::-webkit-scrollbar {
  height: 0;
  width: 0;
  -webkit-appearance: none;
}

// maksym added zeroing
* {
  padding: 0;
  margin: 0;
}

*::selection {
  //   background-color: var(--dark-f-color);
  //   color: var(--bright-f-color);
}

html {
  height: 100%;
}

body {
  margin: 0;
  padding: 0;
  border: 0;
  outline: none;
  background: none;
}

body {
  min-height: 100%;
}

::-webkit-scrollbar {
  height: 0;
  width: 0;
  -webkit-appearance: none;
}

nav-link {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
}

:hover,
:focus,
:active {
  outline: none;
  text-decoration: none;
  // border-style: none;
}

/* Hide Play button + controls on iOS */
video::-webkit-media-controls {
  display: none !important;
}

img {
  box-sizing: border-box;
}

ul {
  padding: 0;
}

input,
textarea,
fieldset {
  border: 0;
  outline: none;
  resize: none;
  -ms-overflow-style: none;
}

a,
b,
p,
h1,
h2,
h3,
h4,
h5,
h6,
ul,
span,
nav-link {
  cursor: auto;
  text-rendering: optimizeLegibility;
}

//
// alex's advice
//
input,
textarea,
div,
li,
ol,
a,
b,
p,
h1,
h2,
h3,
h4,
h5,
h6,
ul,
span,
nav-link {
  font-feature-settings: "ss03" on, "ss04" on;
}

//
//
//

a,
li,
ul,
span,
div {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

a,
a:visited,
a:focus,
a:active,
a:hover {
  text-decoration: none;
  cursor: pointer;
}

li {
  list-style-type: none;
}

body {
  &:not(.scrolling-top) {
    .animation-host.done {
      .y-sync,
      &.y-sync {
        will-change: transform, opacity;
        @include text-animation(0px, 50px);
      }

      .y-sync.hide-bottom,
      &.y-sync.hide-bottom {
        &:not(.once) {
          transform: translateY(-50px);
        }
      }

      .y-sync.enter,
      &.y-sync.enter,
      .y-sync.tall-item.hide-top,
      &.y-sync.tall-item.hide-top {
        &:not(.hide-bottom) {
          opacity: 1;
          transform: translateY(0px);
        }
      }
    }
  }

  &.scrolling-top {
    .animation-host.done {
      .y-sync,
      &.y-sync {
        will-change: transform, opacity;
        @include text-animation(0px, -50px);
      }

      .y-sync:not(.hide-top),
      &.y-sync:not(.hide-top) {
        &:not(.once) {
          transform: translateY(50px) !important;
        }
      }

      .y-sync.enter,
      &.y-sync.enter,
      .y-sync.tall-item.hide-top,
      &.y-sync.tall-item.hide-top {
        &:not(.hide-bottom) {
          opacity: 1;
          transform: translateY(0px);
        }
      }
    }
  }
}