@import "../../styles/global-scss.scss";

:local(.page) {
  width: 100vw;

  :global {
    .main-logo {
      @media screen and (max-width: $br2-end) {
        display: none;
      }
      @media screen and (min-width: $br1-start) {
        --logo-size: 60px;
      }
      z-index: 1;
      @include position(absolute,0,unset,unset,0);
      width: var(--logo-size);
      height: var(--logo-size);
      padding: var(--side-margin);

      > img {
        width: 100%;
        height: 100%;
      }
    }

    .top-section {
      width: 100%;
      height: 100svh;

      // @media screen and (min-width: $br1-2-start) {
      //   height: 100svh;
      //   display: grid;
      //   grid-template-columns: repeat(2, 1fr);
      //   grid-gap: var(--spacing-0);
      // }

      // @media screen and (max-width: $br1-2-end) {
      //   display: flex;
      //   flex-direction: column;
      // }
    }

    .content-wrapper {
      max-width: var(--content-width);
      margin: 0 auto;
      padding: 0 var(--spacing-0);

      > section {
        margin-top: var(--spacing-0);
      }

      > footer {
        padding: var(--spacing-0) 0;

        > * {
          text-align: right;
          text-transform: uppercase;
        }
      }
    }
  }
}
