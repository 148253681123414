@mixin two-app-in-row-at-panel() {
  $halfs-spacing: calc(var(--subdiv) * 2);
  $first-half: calc((100% - #{$halfs-spacing}) / 2);
  &:first-child {
    width: $first-half;
    margin-right: $halfs-spacing;
  }
  &:last-child {
    width: calc(100% - #{$first-half} - #{$halfs-spacing});
  }
}

@mixin card(
  $background:var(--color-G0),
  $borderRadius: var(--radius-1),
  $blur: 50px,
  $shadow: var(--shadow-2)
) {
  background: $background;
  border-radius: $borderRadius;
  backdrop-filter: blur($blur);
  box-shadow: $shadow;
}

@mixin features-header() {
  display: flex;
  flex-direction: column;
  align-items: center;

  > :first-child {
    margin-bottom: var(--spacing10);
  }
}

// .transition.y-sync.exit {
//     @include text-animation(0px, -60px, 0);
// }
// .transition.y-sync {
//     will-change: transform, opacity;
//     @include text-animation(0px, 60px);
// }
// .transition.y-sync.enter {
//     @include text-animation(0px, 0px, 1);
// }
@mixin text-animation(
  $x: 0px,
  $y: 0px,
  $opacity: 0,
  $time: 1s,
  $delay: 0s,
  $function1: $ease-out-apple1,
  $function2: $ease-out-apple2
) {
  opacity: $opacity;
  transform: translate($x, $y);
  transition: transform $time * 1.2 $function1 $delay,
    opacity $time $function2 $delay;
}

@mixin items-align-absolute($wrapper-widht, $padding-top) {
  width: $wrapper-widht;
  margin: 0;
  position: relative;
  overflow: hidden;
  &:before {
    display: block;
    content: "";
    padding-top: $padding-top;
  }
  > img,
  > div,
  > span,
  > p,
  > li {
    position: absolute;
    margin: auto;
    left: 0%;
    top: 0%;
    height: auto;
    width: 100%;
    max-width: initial;
  }
}

@mixin border-bottom-hover-animation {
  &:after {
    @include transition(width);
    content: "";
    display: block;
    border-bottom: 1px solid $color-D0;
    width: 0%;
  }
  &:hover {
    &:after {
      width: 100%;
    }
  }
}

@mixin ios-touch-scroll() {
  -webkit-overflow-scrolling: touch;
  &::-webkit-scrollbar {
    display: none;
  }
}

@mixin transition(
  $property: all,
  $duration: $base-time,
  $delay: 0s,
  $timing-function: $ease-out-apple1
) {
  transition-property: $property;
  transition-duration: $duration;
  transition-timing-function: $timing-function;
  transition-delay: $delay;
}

@mixin bg-blur-image($URL) {
  position: relative;
  &:before {
    @include position(absolute, 0, 0, 0, 0);
    content: "";
    display: block;
    height: 100%;
    width: 100%;
    background-size: 200% !important;
    filter: blur($blur6);
    opacity: 0.3;
    background: url($URL) no-repeat center;
    z-index: 0;
  }
}

@mixin scale-ratio($ratioPers) {
  margin: 0 auto;
  position: relative;
  height: initial;
  &:before {
    content: "";
    display: block;
    padding-top: $ratioPers;
  }
}
//
//    dont used
//
// @mixin multi-line-ellipsis($lineHeight: 1.2em, $lineCount: 1, $bgColor: white){
//   overflow: hidden;
//   position: relative;
//   line-height: $lineHeight;
//   max-height: $lineHeight * $lineCount;
//   margin-right: -1em;
//   padding-right: 1em;
//   &:before {
//     content: '...';
//     position: absolute;
//     right: var(--side-margins);
//     bottom: 0;
//   }
// }

//Position
@mixin position(
  $position: null,
  $top: null,
  $right: null,
  $bottom: null,
  $left: null
) {
  position: $position;
  top: $top;
  right: $right;
  bottom: $bottom;
  left: $left;
}
//Image-Set
@mixin png-image-set($image) {
  @include image-set($image, "png");
}
@mixin jpg-image-set($image) {
  @include image-set($image, "jpg");
}
@mixin gif-image-set($image) {
  @include image-set($image, "gif");
}
@mixin image-set($name, $extension) {
  background-image: url("#{$name}@1x.#{$extension}");
  background-image: -webkit-image-set(
    url("#{$name}@1x.#{$extension}") 1x,
    url("#{$name}@2x.#{$extension}") 2x,
    url("#{$name}@3x.#{$extension}") 3x
  );
  background-image: -moz-image-set(
    url("#{$name}@1x.#{$extension}") 1x,
    url("#{$name}@2x.#{$extension}") 2x,
    url("#{$name}@3x.#{$extension}") 3x
  );
  background-image: -o-image-set(
    url("#{$name}@1x.#{$extension}") 1x,
    url("#{$name}@2x.#{$extension}") 2x,
    url("#{$name}@3x.#{$extension}") 3x
  );
  background-image: -ms-image-set(
    url("#{$name}@1x.#{$extension}") 1x,
    url("#{$name}@2x.#{$extension}") 2x,
    url("#{$name}@3x.#{$extension}") 3x
  );
}

@mixin center-flex() {
  display: flex;
  align-items: center;
  justify-content: center;
}

//
//    dont used
//
// @mixin  hover-active{
//   cursor: pointer;
//   @include transition(opacity $Time3);
//   opacity: 1;
//   &:hover{
//     opacity: $Opacity2;
//   }
//   &:active{
//     opacity: 1;
//   }
// }
@mixin ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@mixin border-opacity($size, $color, $opacity) {
  border: $size solid rgba($color, $opacity);
  -webkit-background-clip: padding-box; /* for Safari */
  background-clip: padding-box; /* for IE9+, Firefox 4+, Opera, Chrome */
}

@mixin text-opacity($color, $opacity) {
  color: $color; /* The Fallback */
  color: rgba($color, $opacity);
}

@mixin background-opacity($color, $opacity) {
  background: $color; /* The Fallback */
  background: rgba($color, $opacity);
}

@mixin visible($value: on) {
  @if $value == on {
    visibility: visible;
    opacity: 1;
  } @else {
    opacity: 0;
    visibility: hidden;
  }
}
@mixin noselection() {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently                               supported by Chrome and Opera */
}
@mixin addsvg($name) {
  background: url($name);
  background-repeat: no-repeat;
  background-position: center;
}

@mixin wh($value) {
  width: $value;
  height: $value;
}

@mixin size($width, $height: null) {
  @if ($height == null) {
    $height: $width;
  }

  height: $height;
  width: $width;
}

@mixin border-radius($radius) {
  -webkit-border-radius: $radius;
  -moz-border-radius: $radius;
  -ms-border-radius: $radius;
  border-radius: $radius;
}

//
//    dont used
//
//@mixin center() {
//    position: relative;
//  width: 60%;
//  left: 20%;
//  top: 35vh;
//  }

// @mixin content-width-2{
//     width: 100%;
//     padding: 0 30px;
//     margin: 0 auto; }

@mixin background-size($bg_size) {
  background-size: $bg_size;
  -ms-background-size: $bg_size;
  -o-background-size: $bg_size;
  -moz-background-size: $bg_size;
  -webkit-background-size: $bg_size;
}
@mixin bg-image {
  background-repeat: no-repeat;
  background-size: cover;
  -ms-background-size: cover;
  -o-background-size: cover;
  -moz-background-size: cover;
  -webkit-background-size: cover;
  background-position: 50% 50%;
  box-sizing: border-box;
}

// @mixin text-columns($cel){
//     -webkit-column-count: $cel;
//     -moz-column-count: $cel;
//     column-count: $cel;}

// @mixin text-columns-gutter($col_gap){
//     -webkit-column-gap: $col_gap;
//     -moz-column-gap: $col_gap;
//     column-gap: $col_gap;
// }
@mixin col-width($col_width) {
  -webkit-column-width: $col_width;
  column-width: $col_width;
}
@mixin col-fill($col_fill) {
  -webkit-column-fill: $col_fill;
  -moz-column-fill: $col_fill;
  column-fill: $col_fill;
}
@mixin col-break($col_break) {
  -webkit-column-break-inside: $col_break;
  -moz-column-break-inside: $col_break;
  column-break-inside: $col_break;
}

@mixin box-shadow($box_shadow) {
  -moz-box-shadow: $box_shadow;
  -webkit-box-shadow: $box_shadow;
  box-shadow: $box_shadow;
}
@mixin transform($transfofm) {
  -ms-transform: $transfofm;
  -webkit-transform: $transfofm;
  transform: $transfofm;
}

@mixin opacity($op_value, $op_value_ie) {
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=$op_value_ie)";
  filter: alpha(opacity=$op_value_ie);
  -moz-opacity: $op_value;
  -khtml-opacity: $op_value;
  opacity: $op_value;
}

// @mixin font-smooth {
//         font-smoothing: antialiased;
//         -moz-font-smoothing: antialiased;
//         -webkit-font-smoothing: antialiased;
// }

@mixin pespective($perspect) {
  -webkit-perspective: $perspect;
  -ms-perspective: $perspect;
  perspective: $perspect;
}
@mixin center-aligment($top, $left) {
  transform: translate3d(0, -50%, 0);
  position: relative;
  top: $top;
  left: $left;
  margin: 0 auto;
}

@mixin box-sizing($parameter) {
  -webkit-box-sizing: $parameter;
  -moz-box-sizing: $parameter;
  box-sizing: $parameter;
}

@mixin placeholder($color) {
  user-select: none;
  ::-webkit-input-placeholder {
    color: ($color);
  }
  ::-moz-placeholder {
    color: ($color);
  }
  :-ms-input-placeholder {
    color: ($color);
  }
  :-moz-placeholder {
    color: ($color);
  }
}

//Gradient

@function convert-angle($value, $unit) {
  $convertable-units: deg grad turn rad;
  $conversion-factors: 1 (10grad/9deg) (1turn/360deg) (3.1415926rad/180deg);
  @if index($convertable-units, unit($value)) and
    index($convertable-units, $unit)
  {
    @return $value /
      nth($conversion-factors, index($convertable-units, unit($value))) *
      nth($conversion-factors, index($convertable-units, $unit));
  }

  @warn "Cannot convert `#{unit($value)}` to `#{$unit}`.";
}

/// Test if `$value` is an angle
/// @param {*} $value - Value to test
/// @return {Bool}
@function is-direction($value) {
  $is-direction: index(
    (
      to top,
      to top right,
      to right top,
      to right,
      to bottom right,
      to right bottom,
      to bottom,
      to bottom left,
      to left bottom,
      to left,
      to left top,
      to top left
    ),
    $value
  );
  $is-angle: type-of($value) == "number" and
    index("deg" "grad" "turn" "rad", unit($value));

  @return $is-direction or $is-angle;
}

/// Convert a direction to legacy syntax
/// @param {Keyword | Angle} $value - Value to convert
/// @require {function} is-direction
/// @require {function} convert-angle
@function legacy-direction($value) {
  @if is-direction($value) == false {
    @warn "Cannot convert `#{$value}` to legacy syntax because it doesn't seem to be an angle or a direction";
  }

  $conversion-map: (
    to top: bottom,
    to top right: bottom left,
    to right top: left bottom,
    to right: left,
    to bottom right: top left,
    to right bottom: left top,
    to bottom: top,
    to bottom left: top right,
    to left bottom: right top,
    to left: right,
    to left top: right bottom,
    to top left: bottom right
  );

  @if map-has-key($conversion-map, $value) {
    @return map-get($conversion-map, $value);
  }

  @return 90deg - convert-angle($value, "deg");
}

/// Mixin printing a linear-gradient
/// as well as a plain color fallback
/// and the `-webkit-` prefixed declaration
/// @access public
/// @param {String | List | Angle} $direction - Linear gradient direction
/// @param {Arglist} $color-stops - List of color-stops composing the gradient
@mixin linear-gradient($direction, $color-stops...) {
  @if is-direction($direction) == false {
    $color-stops: ($direction, $color-stops);
    $direction: 180deg;
  }

  background: nth(nth($color-stops, 1), 1);
  background: -webkit-linear-gradient(
    legacy-direction($direction),
    $color-stops
  );
  background: linear-gradient($direction, $color-stops);
}

//Example
//.test-1 {
//  @include linear-gradient(#31B7D7, #EDAC7D);
//}
//
//.test-2 {
//  @include linear-gradient(to right, #E47D7D 0%, #C195D3 50%, #4FB4E8 100%);
//}
//
//.test-3 {
//  @include linear-gradient(42deg, #B58234 0%, #D2B545 50%, #D7C04D 50.01%, #FFFFFF 100%);
//}

@mixin black-or-white($value: w) {
  @if $value == w {
    & a,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    ul,
    li,
    p {
      color: $color-W0 !important;
    }
  } @else {
    & a,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    ul,
    li,
    p {
      color: $color-D0;
    }
  }
}

// @mixin font-family($f_family,$f_weight,$f-size,$l_spacing,$Leading,$uplowcase) {
//   $l: line-height($f-size, $Leading);

//   font-family: $f_family, sans-serif, open-sans;
//   font-weight: $f_weight;
//   font-size: $f-size;
//   letter-spacing: $l_spacing;
//   line-height: $l;
//   text-transform: $uplowcase;
//   padding: font-space($f-size, $l);
// }

@mixin parallax-element($z: -1, $perspective: 1) {
  $scale: 1 + (($z * -1) / $perspective);
  -webkit-transform: translateZ($z + px) scale($scale);
  transform: translateZ($z + px) scale($scale);
}

@mixin gallery($col, $spacing, $spacing_v: $spacing) {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  > * {
    height: fit-content;
    width: calc(100% / #{$col} - #{$spacing} * (#{$col} - 1) / #{$col});
    &:nth-child(#{$col}n) {
      &:not(:last-child) {
        margin-bottom: #{$spacing};
      }
    }
    &:not(:nth-child(#{$col}n)) {
      &:not(:last-child) {
        margin-right: #{$spacing_v};
      }
    }
  }
}
