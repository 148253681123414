/*
	shuster i partnery v1.15
	(c) Flareum, 2023
*/
html {
  @import './global-scss.scss';
  /* colors */
  --color-A0: rgba(231, 231, 224, 1);
  --color-P0: rgba(122, 99, 240, 1);
  --color-B0: rgba(116, 224, 224, 1);
  --color-C0: rgba(30, 30, 16, 1);
  --color-D0: rgba(12, 12, 0, 1);
  /* fonts out of breakpoints */
  --font-f1: 'Roboto';

  --shadow-color-1: rgba(139, 155, 207, 0.1);
  --shadow-color-2: rgba(139, 155, 207, 0);
  // --border-color: var(--color-W0);
  // --border-color-a: var(--color-W3-a);
  // --list-circle-color: var(--color-B2-tint-a);
  // EFFECTS
  --radius-1: 25px;
  --radius-2: 9999px;
  --shadow-1: 0px 30px 60px var(--shadow-color-1);
  --shadow-1-a: 0px 30px 60px var(--shadow-color-2);
  --shadow-2: inset 0px 1px 7px var(--color-W0);
  --shadow-2-a: inset 0px 1px 7px transparent;

  --spacing-0: var(--side-margin);
  --spacing-1: var(--baseline);
  --spacing-2: calc(var(--subline) * 2);
  --spacing-3: var(--subline);

  @media screen and (max-width: $br2-end) {
    /* grid */
    --side-margin: 20px;
    --baseline: 24px;
    --subline: 6px;
    --gutter: 24px;
    --column1: 78px;
    --column2: 180px;
    --column3: 282px;
    --column4: 384px;
    --column5: 486px;
    --column6: 588px;
    --column7: 690px;
    --column8: 792px;
    --column9: 894px;
    --column10: 996px;
    --column11: 1098px;
    --column12: 1200px;
    --content-width: var(--column12);
    /* fonts */
    --font-s-1: 11px;
    --font-s-2: 8px;
    --font-s-3: 8px;
    --font-s0: 14px;
    --font-s1: 17px;
    --font-s2: 22px;
    --font-s3: 31px;
    --font-s4: 54px;
    --font-s5: 97px;
    --font-s6: 114px;
    --font-s7: 120px;
  }

  @media screen and (max-width: $br1-end) and (min-width: $br1-start) {
    /* grid */
    --side-margin: 30px;
    --baseline: 24px;
    --subline: 6px;
    --gutter: 24px;
    --column1: 78px;
    --column2: 180px;
    --column3: 282px;
    --column4: 384px;
    --column5: 486px;
    --column6: 588px;
    --column7: 690px;
    --column8: 792px;
    --column9: 894px;
    --column10: 996px;
    --column11: 1098px;
    --column12: 1200px;
    --content-width: var(--column12);
    /* fonts */
    --font-s-1: 13px;
    --font-s-2: 9px;
    --font-s-3: 8px;
    --font-s0: 16px;
    --font-s1: 19px;
    --font-s2: 24px;
    --font-s3: 32px;
    --font-s4: 55px;
    --font-s5: 97px;
    --font-s6: 114px;
    --font-s7: 120px;
  }

  /* primary breakpoint - $br0-end(1025)px */
  @media screen and (min-width: $br0-start) {
    /* grid */
    --side-margin: 40px;
    --baseline: 24px;
    --subline: 6px;
    --gutter: 24px;
    --column1: 78px;
    --column2: 180px;
    --column3: 282px;
    --column4: 384px;
    --column5: 486px;
    --column6: 588px;
    --column7: 690px;
    --column8: 792px;
    --column9: 894px;
    --column10: 996px;
    --column11: 1098px;
    --column12: 1200px;
    --content-width: var(--column12);
    /* fonts */
    --font-s-1: 13px;
    --font-s-2: 9px;
    --font-s-3: 8px;
    --font-s0: 16px;
    --font-s1: 19px;
    --font-s2: 24px;
    --font-s3: 32px;
    --font-s4: 55px;
    --font-s5: 97px;
    --font-s6: 114px;
    --font-s7: 120px;
  }
}
